// extracted by mini-css-extract-plugin
export var column = "UserColocationRfpProject_Details_Locations__column__zze3r";
export var datacentersCountText = "UserColocationRfpProject_Details_Locations__datacentersCountText__XTNrx";
export var editButton = "UserColocationRfpProject_Details_Locations__editButton__dhbuG";
export var emptyProviders = "UserColocationRfpProject_Details_Locations__emptyProviders__mUq6n";
export var headingContentWrapper = "UserColocationRfpProject_Details_Locations__headingContentWrapper__ftniM";
export var locationHeading = "UserColocationRfpProject_Details_Locations__locationHeading__MWZiV";
export var mapContainer = "UserColocationRfpProject_Details_Locations__mapContainer__c5MdY";
export var mapElement = "UserColocationRfpProject_Details_Locations__mapElement__q_joi";
export var providerDetailRow = "UserColocationRfpProject_Details_Locations__providerDetailRow__vfmQe";
export var providerImage = "UserColocationRfpProject_Details_Locations__providerImage__P5NWq";
export var providerName = "UserColocationRfpProject_Details_Locations__providerName__JTDCE";
export var providerPanel = "UserColocationRfpProject_Details_Locations__providerPanel__BW_BW";
export var providerPanelBody = "UserColocationRfpProject_Details_Locations__providerPanelBody__vjulR";
export var providerPanelHeadingContainer = "UserColocationRfpProject_Details_Locations__providerPanelHeadingContainer__bST65";
export var providerPanelImageAndNameWrapper = "UserColocationRfpProject_Details_Locations__providerPanelImageAndNameWrapper__VLAS5";
export var providerTable = "UserColocationRfpProject_Details_Locations__providerTable__TCBTj";
export var providerTableHead = "UserColocationRfpProject_Details_Locations__providerTableHead__s9T8d";
export var providerTableHeadingContainer = "UserColocationRfpProject_Details_Locations__providerTableHeadingContainer__ht37d";
export var providerTitle = "UserColocationRfpProject_Details_Locations__providerTitle__J8qXW";
export var providersWrapper = "UserColocationRfpProject_Details_Locations__providersWrapper__h_QEX";
export var quantityText = "UserColocationRfpProject_Details_Locations__quantityText__MBofK";
export var roleText = "UserColocationRfpProject_Details_Locations__roleText__o0MlH";
export var row = "UserColocationRfpProject_Details_Locations__row__c2ei6";