// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as React from "react";
import * as Picture from "../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as UserColocationRfpProject_Details_LocationsScss from "./UserColocationRfpProject_Details_Locations.scss";

var css = UserColocationRfpProject_Details_LocationsScss;

function datacenterTextWithCountDistinction(x) {
  if (x !== 1) {
    return " data centers";
  } else {
    return " data center";
  }
}

function UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelHeading(props) {
  var dataCentersCount = props.dataCentersCount;
  var providerName = props.providerName;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("img", {
                              className: css.providerImage,
                              alt: providerName,
                              src: props.providerImage
                            }),
                        JsxRuntime.jsx("h5", {
                              children: providerName,
                              className: css.providerName
                            })
                      ],
                      className: css.providerPanelImageAndNameWrapper
                    }),
                JsxRuntime.jsx("p", {
                      children: String(dataCentersCount) + datacenterTextWithCountDistinction(dataCentersCount),
                      className: css.datacentersCountText
                    })
              ],
              className: css.providerPanelHeadingContainer
            });
}

var PanelHeading = {
  make: UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelHeading
};

function UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelBody(props) {
  var toggleDirection = props.toggleDirection;
  var sortDirection = props.sortDirection;
  var sortedLocations = Belt_SortArray.stableSortBy(props.locations, (function (x1, x2) {
          if (sortDirection === "Desc") {
            return Caml.string_compare(x2.name, x1.name);
          } else {
            return Caml.string_compare(x1.name, x2.name);
          }
        }));
  return JsxRuntime.jsxs("table", {
              children: [
                JsxRuntime.jsxs("colgroup", {
                      children: [
                        JsxRuntime.jsx("col", {
                              width: "50%"
                            }),
                        JsxRuntime.jsx("col", {
                              width: "50%"
                            })
                      ]
                    }),
                JsxRuntime.jsx("thead", {
                      children: JsxRuntime.jsxs("tr", {
                            children: [
                              JsxRuntime.jsxs("td", {
                                    children: [
                                      JsxRuntime.jsx("p", {
                                            children: "Title"
                                          }),
                                      JsxRuntime.jsx(IconSorting.make, {
                                            size: "XS",
                                            direction: sortDirection
                                          })
                                    ],
                                    className: css.providerTableHeadingContainer,
                                    onClick: (function (param) {
                                        toggleDirection();
                                      })
                                  }),
                              JsxRuntime.jsx("td", {
                                    children: JsxRuntime.jsx("p", {
                                          children: "Address"
                                        }),
                                    className: css.providerTableHeadingContainer
                                  })
                            ]
                          }),
                      className: css.providerTableHead
                    }),
                JsxRuntime.jsx("tbody", {
                      children: Belt_Array.map(sortedLocations, (function ($$location) {
                              return JsxRuntime.jsxs("tr", {
                                          children: [
                                            JsxRuntime.jsxs("td", {
                                                  children: [
                                                    JsxRuntime.jsx(Picture.make, {
                                                          src: $$location.image,
                                                          large: [
                                                            60,
                                                            40
                                                          ],
                                                          fallbackSrc: $$Location.imageNotFoundUrl
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: $$location.url,
                                                                targetBlank: true,
                                                                children: $$location.name
                                                              })
                                                        })
                                                  ],
                                                  className: css.providerTitle
                                                }),
                                            JsxRuntime.jsx("td", {
                                                  children: $$location.fullAddress
                                                })
                                          ],
                                          className: css.providerDetailRow
                                        }, $$location.name + $$location.fullAddress);
                            }))
                    })
              ],
              className: css.providerTable
            });
}

var PanelBody = {
  make: UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelBody
};

function toggleDirection(x) {
  if (x === "Desc") {
    return "Asc";
  } else {
    return "Desc";
  }
}

function UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel(props) {
  var provider = props.provider;
  var match = React.useReducer((function (state, action) {
          return {
                  sortDirection: toggleDirection(state.sortDirection)
                };
        }), {
        sortDirection: "Asc"
      });
  var dispatch = match[1];
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.providerPanel,
              bodyClassName: css.providerPanelBody,
              heading: JsxRuntime.jsx(UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelHeading, {
                    providerName: provider.name,
                    providerImage: provider.providerImage,
                    dataCentersCount: provider.locations.length
                  }),
              children: JsxRuntime.jsx(UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel$PanelBody, {
                    locations: provider.locations,
                    sortDirection: match[0].sortDirection,
                    toggleDirection: (function () {
                        dispatch("ToggleSortDirection");
                      })
                  })
            });
}

var make = UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel;

export {
  css ,
  datacenterTextWithCountDistinction ,
  PanelHeading ,
  PanelBody ,
  toggleDirection ,
  make ,
}
/* css Not a pure module */
